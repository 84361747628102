@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
}
@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-LightItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}