.dashboardComponent
{
    position: relative;
}
.heading {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  margin-top: 6px;
  color: #000;
}
.sxp-defaultDropDown {
  background-color: #086284;
  color: #fff!important;
  padding: 10px 20px;
  border-radius: 14px;
  .MuiInputBase-input
  {
    padding: 0;
  }
  .MuiSelect-icon
  {
    color: #fff;
  }
}
.carouselWidth {
  width: calc(100vw - 316px);
}
.journeySlider {
  padding: 20px 0;
  .text-overfl-card {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  ul {
    li:first-child {
      div {
        margin-left: 0;
      }
    }
    li:last-child {
      div {
        margin-right: 0;
      }
    }
  }
  .react-multiple-carousel__arrow--right {
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
    right: 0px;
    min-height: unset;
    min-width: unset;
    height: 38px;
    width: 38px;
    &:hover {
      background-color: #fff;
    }
    &:before {
      color: #000;
      font-weight: 800;
      font-size: 16px;
    }
  }
  .react-multiple-carousel__arrow--left {
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
    left: 0px;
    min-height: unset;
    min-width: unset;
    height: 38px;
    width: 38px;
    &:hover {
      background-color: #fff;
    }
    &:before {
      color: #000;
      font-weight: 800;
      font-size: 16px;
    }
  }
  li {
    div {
      text-align: center;
      background-color: #444;
      color: #fff;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
      margin: 6px;
      border-radius: 50px;
      font-size: 14px;
      padding: 11px;
      cursor: pointer;
      &:hover {
        background-color: #086284;
        color: #fff;
      }
    }
    div.activejour {
      background-color: #086284;
      color: #fff;
    }
  }
}
.singleWidget {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
  text-align: center;
  flex: 1;
  margin: 0 11px;
  border-radius: 26px;
  padding: 20px;
  padding: 20px 15px 4px 15px;
  position: relative;
  overflow: hidden;
  h4 {
    font-size: 32px;
  }
  h6 {
    text-transform: uppercase;
    margin-bottom: 6px;
  }
  svg {
    color: #f48019;
  }
}
.widgets {
  .singleWidget:first-child {
    margin-left: 0;
  }
  .singleWidget:last-child {
    margin-right: 0;
  }
}
.dashboardGridBlock1
{
  display: inline-block;
    padding: 0 20px;
    padding-left: 0;
    width: 61%;
}
.dashboardGridBlock2 {
  display: inline-block;
  width: 39%;
  vertical-align: top;
  padding: 0 px;
}
.chartbox
{
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
  padding: 20px;
  padding-top: 30px;
  margin-top: 30px;
  border-radius: 14px;
  position: relative;
  overflow: hidden;
  h1 {
    font-size: 22px;
    margin-top: 0;
    padding-left: 20px;
    margin-top: 10px;
  }
}
.makePosTR
{
  position: absolute;
  right: 0;
  top: 0;
}
.changeChartBtn {
  background-color: #086284;
  color: #fff;
  margin-right: 10px;
  padding: 8px 18px;
  border-radius: 0 0px 14px 14px;
  cursor: pointer;
}
.btn-radius-bottom-left {
  border-radius: 0px 0px 0px 14px !important;
}
.btnMiddle {
  margin-right: 10px;
}
.btn-radius-bottom-left-right {
  border-radius: 0px 0px 14px 14px !important;
}
.statisticBlock {
  .statisticCard:first-child {
    margin-left: 0;
  }
  .statisticCard:last-child {
    margin-right: 0;
  }
}
.contentConver {
  flex: 2 1;
  margin-top: 24px;
}
.statisticCard {
  position: relative;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  padding: 20px;
  margin-bottom: 20px;
  flex: 1;
  margin: 10px;
  width: 200px;
  margin-top: 30px;
  overflow: hidden;
  .badgeYear {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #086284;
    color: #fff;
    padding: 9px 5px;
    font-size: 14px;
    padding-left: 11px;
    border-radius: 0 9px;
  }
  h3 {
    font-size: 18px;
    margin: 0;
  }
  h4 {
    font-size: 32px;
    margin-left: 20px;
  }
  ul {
    li {
      list-style-type: none;
      display: inline-block;
      margin-right: 20px;
      font-size: 13px;
      color: #464646;
    }
  }
}
.noDataJourney
{
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  padding: 20px;
  text-align: center;
  border-radius: 50px;
}