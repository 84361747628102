.sxp-features
{
    div
    {
        margin-bottom: 20px;
        p
        {
            font-size: 13px;
            color: #086284;
            margin-bottom: 8px;
        }
        span
        {
            font-size: 12px;
            padding: 6px 12px;
            background-color: #4ECFFF;
            color: #086284;
            border-radius: 4px;
            font-weight: 500;
            img
            {
                width: 18px;
                margin-right: -6px;
                margin-top: 4px;
                vertical-align: text-bottom;
                &:first-child
                {
                    margin-left: 10px;
                }
            }
        }
    }
}
.projectDesc
{
    font-size: 14px;
    color: #086284;
    margin-bottom: 20px;
}
.profileTab
{
    ul
    {
        li
        {
            list-style-type: none;
            display: inline-block;
            padding: 10px 30px;
            font-size: 14px;
            cursor: pointer;
        }
    }
}
.active-tab
{
    border-bottom: 4px solid #086284;
}
.tabsBorder
{
    background-color: #B1D0DC;
    margin-bottom: 13px;
}
.langSelectPlaceholder
{
    position: absolute;
    left: 11px;
    top: 7px;
    z-index: 2;
}
.roleSelectPlaceholder
{
    position: absolute;
    left: 15px;
    top: 21px;
    z-index: 0;
}
.usersearchInput{
    position: relative;
    .MuiAutocomplete-input
    {
        background-color: #B1D0DC;
        height: 34px;
        border: 0;
        border-radius: 8px;
        padding-left: 10px;
        margin-left: 5px;
        margin-top: 15px;
        width: 220px;
        margin-right: 5px;
    }
    .downArrrowChev
    {
        position: absolute;
        top: 22px;
        width: 22px;
        right: 10px;
    }
}
.userAddBLock
{
    width: 482px;
    margin: 0 auto;
}
.userAddHeader
{
    label
    {
        width: 220px;
        margin-left: 5px;
        margin-right: 5px;
        font-size: 14px;
        font-weight: 600;
        color: #086284;
    }
}
.deleteUserIcon
{
    margin-top: 24px;
    margin-left: 7px;
    cursor: pointer;
}
.langu_check
{
    width: 42px;
    margin-right: 50px;
    height: 16px;
    margin-top:15px
}
.noUserText {
    font-size: 13px;
    color: #086284;
    font-weight: 600;
    margin-top: 20px;
}
.auth_popup
{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 100;
    background-color: #fff;
    padding: 10px 40px;
    width: 372px;
    border-radius: 8px;
    input
    {
        width: 100%;
        height: 34px;
        border: 0;
        background-color: #B1D0DC;
        border-radius: 8px;
        padding-left: 10px;
        margin-bottom: 15px;
    }
    button
    {
        width: 100%;
        height: 34px;
        border: 0;
        background-color: #086284;
        color: #fff;
        border-radius: 8px;
        margin-bottom: 10px;
    }
    label
    {
        margin-bottom: 10px;
        font-size: 13px;
        font-weight: 600;
        display: inline-block;
    }
}
.projectCreateNew
{
    .newVar
    {
        padding-left: 15px;
        padding-right: 15px;
    }
    .varHead
    {
        margin-bottom: 20px;
    }
    .jwtc
    {
        margin-bottom: 10px;
    }
    .jwtDiv
    {
        margin-bottom: 10px;
    }
    input{
        margin-bottom: 10px;
    }
}

.jwtFields
{
    input{
        background-color: #B1D0DC;
        height: 34px;
        border: 0;
        border-radius: 8px;
        padding-left: 10px;
        margin-left: 5px;
        margin-top: 15px;
        width: 220px;
        margin-right: 5px;
    }
}

.hideInputNumberArrows::-webkit-outer-spin-button, .hideInputNumberArrows::-webkit-inner-spin-button 
{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
    -moz-appearance: textfield;
}
.handleEye
{
    position: absolute;
    top: 9px;
    right: 10px;
}