.profileTab
{
    ul
    {
        li
        {
            list-style-type: none;
            display: inline-block;
            padding: 10px 30px;
            font-size: 14px;
            cursor: pointer;
        }
    }
}
.active-tab
{
    border-bottom: 4px solid #086284;
}
.profileImage {
    width: 250px;
    background-color: #535353;
    text-align: center;
    border-radius: 14px;
    height: 340px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.user_data {
    div {
      font-size: 14px;
      color: #086284;
      p {
        font-size: 13px;
        margin-top: 0;
        color: #262626;
        margin-bottom: 20px;
      }
    }
    .label {
      flex: 1;
      align-self: center;
      margin-top: -12px;
    }
    .inputField {
      margin-bottom: 15px;
      flex: 2;
      input,
      select {
        background-color: rgba(8, 98, 132, 0.2);
        border: 0;
        height: 42px;
        border-radius: 14px;
        width: 100%;
        padding-left: 14px;
      }
      textarea {
        background-color: rgba(8, 98, 132, 0.2);
        border: 0;
        height: 92px;
        border-radius: 14px;
        width: 100%;
        padding: 14px;
      }
    }
  }