.configList
{
    background-color: #fff;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0px 4px 25px rgba( 0, 0, 0, 0.15);
    margin-top: 12px;
    h5
    {
        margin: 22px;
    }
    .editBtn
    {
        background-color: #f58819;
        margin: 13px 30px 0 0;
    }
    .jwtFields
    {
        margin-bottom: 10px;
    }
}

.state-form {
    label{
        font-size: 15px;
        display: block;
    }
    input
    {
        width: 400px;
        height: 34px;
        border-radius: 8px;
        background-color: #C6D8DF;
        border:1px solid #94b3bf;
        padding-left: 10px;
        margin-bottom: 15px;
    }
    textarea{
        width: 400px;
        height: 180px;
        border-radius: 8px;
        background-color: #C6D8DF;
        border:1px solid #94b3bf;
        padding-left: 10px;
        margin-bottom: 15px;
    }
}

.stateBtn
{
    width: 100px;
    height: 34px;
    color: #fff;
    border: 0;
    border-radius: 8px;
    background-color: #086284;
    margin-bottom: 15px;
}