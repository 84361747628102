.createConvDiv {
  position: fixed;
  width: 380px;
  background-color: #cee0e6;
  padding: 17px;
  box-shadow: 2px 2px 6px #7a7a7a;
  z-index: 100;
  right: 0;
  top: 71px;
  height: calc(100vh - 71px);
  overflow-y: scroll;
  label {
    display: inline-block;
  }
  p{color: #086284;}
  h3 {
    color: #086284;
    margin-top: 10px;
  }
  h4 {
    color: #000;
    margin-top: 10px;
  }
  input {
    height: 34px;
    border: 1px solid #086284;
    background-color: transparent;
    border-radius: 8px;
    margin-bottom: 15px;
    width: 100%;
    padding-left: 14px;
  }
  input[type='checkbox'] {
    height: 18px;
    width: 18px;
    border: 1px solid #086284;
    background-color: transparent;
    vertical-align: -3px;
    margin-right: 7px;
  }
  textarea {
    height: 88px;
    border: 1px solid #086284;
    background-color: transparent;
    border-radius: 8px;
    margin-bottom: 10px;
    width: 100%;
    padding-left: 14px;
  }
}
.applyBtn
{
  width: 100%;
  height: 34px;
  color: #fff;
  border: 0;
  border-radius: 8px;
  background-color: #086284;
  margin-bottom: 15px;
}
.deleBtn
{
  width: 100%;
  height: 34px;
  color: #fff;
  border: 0;
  border-radius: 8px;
  background-color: #ec5b56;
  margin-bottom: 15px;
}
.flow-content
{
  border-radius: 0 0 5px 5px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex
}
.actionPack {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 20px;
  display: inline-block;
  div img
  {
    width: 36px;
  }
}
.flow-parent {
  width: 100%;
  height: 100%;
}

.rightSlide {
  position: fixed;
  top: 0px;
  width: 280px;
  background-color: #cee0e6;
  border-radius: 14px;
  right: 0;
  height: calc(100vh - 92px);
  transition: 0.5s;
  z-index: 1101;
}
.ConfigPanelClose {
  right: -280px;
  .to-close-panel-arrow {
    transform: rotate(180deg);
  }
}
.rightOpenArrow {
  position: absolute;
  left: -82px;
  height: 32px;
  text-align: center;
  padding: 4px;
  background: #cee0e6;
  top: 50%;
  cursor: pointer;
  transform: rotate(270deg);
}
.panelHeaderText {
  margin: 0;
  padding: 12px;
  background-color: #004158;
  color: #fff;
  border-radius: 10px 10px 0 0;
  text-align: center;
}
.pannelTExt {
  white-space: nowrap;
  display: inline-block;
  vertical-align: top;
  margin-top: 4px;
  margin-left: 6px;
}
.handleModellerIcon {
  width: 36px;
  height: 36px;
  display: inline-block;
  border-radius: 50%;
  padding: 8px;
  margin-bottom: 5px;
  img {
    width: 100%;
  }
}
.dynode {
  background-color: #264653;
}
.userReg {
  background-color: rgb(204, 84, 63);
}
.cloud-btn{
  background: #cee0e6;
  color: #086284;
  margin-right: 5px;
  height: 34px;
  font-size: 13px;
  border: 0;
  display: inline-block;
  padding: 8px 20px;
  border-radius: 8px;
  .icons {
    height: 10px;
    cursor: pointer;
    margin-right: 8px;
  }
  input[type="file"]{
    display: none;
  }

}
.config-btn
{
  background: #086284;
    border-radius: 8px;
    font-size: 12px;
    color: #fff;
    height: 25px;
    border: 0;
    margin-top: 12px;
    padding: 0 12px;
}
.react-flow
{
  height: 500px !important;
}
.fullScreenMode
{
  .react-flow{
    height: calc(100% - 63px) !important; 
  }
}
.chevron-range {
  height: 34px;
  cursor: pointer;
  padding: 5px;
}
.scrollableContent {
  max-height: 92%;
  overflow-y: scroll;
  padding: 20px;
}
.form-style
{
  .sxp-defaultDropDown_filed
  {
    margin-bottom: 15px;
  }
  label{
    font-size: 15px;
  }
  input
  {
    width: 100%;
    height: 34px;
    border-radius: 8px;
    background-color: #C6D8DF;
    border:1px solid #94b3bf;
    padding-left: 10px;
    margin-bottom: 15px;
  }
  textarea{
    width: 100%;
    height: 100px;
    border-radius: 8px;
    background-color: #C6D8DF;
    border:1px solid #94b3bf;
    padding-left: 10px;
    margin-bottom: 15px;
  }
  input[type="checkbox"]{
    height: auto;
    width: auto;
  }
}
.notifi_channel {
  width: 100%;
  .channel-select-dd {
    border: 1px solid #086284;
    height: 34px;
    border-radius: 8px;
    font-size: 13px;
    padding-left: 10px;
  }
  .selectChannelButton {
    top: 5px;
    position: absolute;
    left: 15px;
  }
}
.flex-box-header {
  display: flex;
  margin-top: 15px;
  margin-left: 20px;
  justify-content: center;
}
.channel-box {
  width: 30px;
  height: 30px;
  margin-left: -10px;
}
.search-div
{
  background-color: transparent;
    display: flex;
    margin-bottom: 20px;
}
// selectDropDwn styles
.statusDiv {
  background: #b1d0dc;
  border-radius: 15px;
  color: #086284;
  margin-left: 5px;
  height: 34px;
  display: inline-block;
  width: 50%;
  text-align: center;
  font-size: 14px;
  padding: 9px;
}
.activeStatus {
  background: #086284;
  color: #b1d0dc;
}

.multi-select {
  .MuiSvgIcon-root.MuiSelect-icon {
    font-size: 16px;
    top: calc(50% - -2px);
    color: #fff;
  }
  .MuiSelect-select.MuiSelect-select {
    padding-right: 0;
  }
}
.menuCustomItem {
  .MuiListItem-root {
    padding-left: 3px;
    padding-right: 7px;
    img {
      width: 24px;
      margin-right: 10px;
    }
  }
}
.jourLvlChanBtn {
  margin-top: -19px !important;
  background-color: #086284;
  color: #fff !important;
  padding: 3px 19px;
  border-radius: 8px;
  margin-left: 0 !important;
}
.cloud-btn-enable {
  width: 105px;
  color: #cee0e6;
  border-radius: 8px;
  font-size: 12px;
  background-color: #086284;
  height: 34px;
  border: transparent;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  .icons
  {
    height: 10px;
    cursor: pointer;
    margin-right: 8px;
  }
}
.makeBelowBtn {
  position: absolute;
  bottom: -26px;
  left: 50%;
  transform: translateX(-50%);
}
.closeExpot {
  position: absolute;
  right: -16px;
  top: 10px;
  cursor: pointer;
}

// reco type styles 
.recotypeSelectStyle {
  width: 87%;
  display: inline-block;
  vertical-align: top;
  margin-right: 8px;
  .MuiFormControl-root {
    width: 100%;
    border: 1px solid #086284;
    border-radius: 8px;
  }
  .MuiInput-input {
    font-size: 13px;
    padding-left: 15px;
    color: #086284;
    font-weight: 600;
  }
}
.handlePlaceholder {
  position: absolute;
  font-size: 13px !important;
  padding-left: 15px;
  margin-top: 5px !important;
}

.reco-Column {
  background-color: #c2f0ff;
  margin: -17px;
  margin-top: 0px;
  padding: 17px;
  select option {
    background-color: transparent;
  }
  .journeySlider li div {
    background-color: #cee0e6;
    color: #086284;
  }
  .journeySlider li div.activejour {
    background-color: #086284;
    color: #fff;
  }
  .journeySlider .react-multiple-carousel__arrow--left {
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    margin-top: -1px;
    left: -24px;
  }
  .journeySlider .react-multiple-carousel__arrow--right {
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    margin-top: -1px;
    right: -24px;
  }
  .react-multi-carousel-list {
    overflow: inherit;
  }
}
.IconBorderCircular {
  background-color: #086284;
  height: 34px;
  width: 34px;
  border-radius: 8px;
  padding: 8px;
}
.recommend-text {
  width: 101px;
  height: 14px;
  font-size: 12px;
  line-height: 1px;
  margin-left: 20px;
  letter-spacing: 0.5px;
  color: #000000;
}
.customeScrollCar {
  li {
    div {
      margin: 0;
      border-radius: 0;
    }
  }
}
.selectReco_jour {
  .selectDDinMulti {
    margin-left: 5px;
    margin-right: 5px;
  }
  .sxp-defaultDropDown_filed
  {
    z-index: 1;
    background-color: transparent;
    border:1px solid #B1D0DC;
  }
  .pos-rel
  {
    width: 46%;
  }
}
.addRecoParamBtn {
  background-color: #13a5dc;
  color: #fff;
  height: 34px;
  display: inline-block;
  margin-bottom: 10px;
  font-size: 13px;
  padding: 5px 17px;
  border-radius: 8px;
}
.modelParamsDD
{
  z-index: 0;
}

.searchSelectDDCS
{
  margin-bottom: 15px;
  input{
    margin-bottom: 0 ;
  }
  .css-b8ldur-Input, .css-g1d714-ValueContainer
  {
    margin: 0;
    padding: 0;
  }
  .css-1okebmr-indicatorSeparator{
    width: 0;
  }
  .css-lpahdxg-control{
    border-color: #94b3bf;
    box-shadow: none;
  }
  // .css-yk16xz-control
  // {
  //   background-color: #C6D8DF;
  //   border: 1px solid #94b3bf;
  // }
  .css-1uccc91-singleValue
  {
    font-size: 13px;
    color: #000;
  }
  .css-tlfecz-indicatorContainer
  {
    color: #444;
  }

}
.searchSelectDDCS > div{
  background-color: #C6D8DF;
  border: 1px solid #94b3bf;
}
.addNonDyBtn
{
  background-color: #086284;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  padding: 3px 8px;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 7px;
}
.nonDynBtnObj
{
  margin-bottom: 15px;
  .flex input
  {
    margin: 3px;
  }
}
.closeIconM
{
  margin-top: 12px;
  cursor: pointer;
}
.mainComponent
{
  border: 1px solid #444;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  h3{
    margin: 0;
    font-size: 13px;
    padding: 6px 0;
    border-bottom: 1px solid #444;
  }
  h4
  {
    margin: 15px 7px;
    font-size: 15px;
  }
}
.react_flow_handle_top {
  width: 10px !important;
  height: 10px !important;
  top: -5px !important;
}
.react_flow_handle_bottom
{
  width: 10px !important;
  height: 10px !important;
  bottom: -5px !important; 
}
.react_flow_handle_bottom_start
{
  width: 8px !important;
  height: 8px !important;
  bottom: 1px !important; 
}
.react_flow_handle_bottom_end
{
  width: 8px !important;
  height: 8px !important;
  top: -3px !important; 
}
.react_flow_handle_wait_bottom
{
  width: 8px !important;
  height: 8px !important;
  bottom: 1px !important; 
}
.react_flow_handle_wait_top
{
  width: 8px !important;
  height: 8px !important;
  bottom: 1px !important; 
}
.holdStyles
{
  min-width: 133px;
  box-shadow: 1px 1px 6px rgb(222, 222, 222);
  overflow: hidden;
  color: rgb(255, 255, 255);
  text-align: center;
}
.fullScreenMode
{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2022;
  background-color: #fff;
  padding-left: 20px;
  padding-right: 20px;
}
.fullScreenIcon
{
  border: 0;
  background-color: transparent;
  img{ 
    width: 28px;
  }
}
.img_node_overlay
{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 50%;
}
#menu-userType, #menu-dialogId, #menu-.MuiPopover-root, #menu-evalAnswer, #menu-recommendationType
{
  z-index: 2023 !important;
}
.MuiTooltip-popper
{
  z-index: 2023 !important;
}
.gotoAnchor
{
  height: 38px;
  margin-top: 4px;
  background-color: #156284;
  border-radius: 4px;
  margin-left: 5px;
  color: #fff;
  padding: 7px 1px;
  cursor: pointer;
}
.workAsLeftArrow
{
  transform: rotate(180deg);
}
.arrowsSwitchJourney
{
  button
  {
    border: 0;
    background-color: #156284;
    color: #fff;
    height: 34px;
    margin-right: 5px;
    border-radius: 8px;
    cursor: pointer;
  }
}
.langQuestion
{
  .spanTextHolder
  {
    border: 1px solid #94b3bf;
    height: 34px;
    align-self: flex-start;
    background-color: white;
    padding: 7px 0;
    border-radius: 8px;
    margin-right: 3px;
    pointer-events: none;
    min-width: 34px;
    text-align: center;
    opacity: 0.5;
  }
  .langCloseIcon
  {
    margin-left: 10px;
    cursor: pointer;
  }

}
.apiRepEle
{
  background-color: #fb792b;
  display: block;
  height: 36px;
  width: 36px;
  border-radius: 50px;
  padding: 5px;
  margin-bottom: 4px;
  svg{
    .cls-1, .cls-2
    {
      stroke: #fff;
      stroke-width: 14px;
    }
  }
}
.infoIcon{
  position: relative;
  right: -14px;
  img{
    width: 16px;
    cursor: pointer;
  }
  .toolTip
  {
    position: absolute;
    background-color: #fff;
    z-index: 4;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    top: -12px;
    right: 25px;
    width: 200px;
    display: none;
    p{
      margin: 0;
      margin-bottom: 6px;
    }
    ul{
      margin: 0;
      li{
        
      }
    }
    &::after{
      position: absolute;
      right: -18px;
      content: '';
      border-left: 10px solid #fff;
      border-right: 10px solid #ff000000;
      border-bottom: 10px solid #00800000;
      border-top: 10px solid #0000ff00;
      top: 8px;
    }  
  }
}

.infoIcon:hover .toolTip{
  display: block;
}