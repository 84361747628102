.headerBgColor
{
    background-color: #086284 !important;
}
.profileIconDD
{
    .MuiPopover-paper
    {
        left: auto !important;
        right: 16px;
        // background-color: #cee0e6;
        color: rgba(0, 0, 0, 0.54);
        .MuiMenuItem-root
        {
            font-size: 13px;
        }        
        .MuiSvgIcon-root
        {
            margin-right: 8px;
            height: 22px;
        }
        a 
        {
            color: #616166;
            text-decoration: none;
            .MuiSvgIcon-root{
            vertical-align: -7px;}
        }
    }
}
.projectsDD
{    
    .MuiInput-input
    {
        color: #fff;
    }
    .MuiSelect-icon
    {
        color: #fff;
    }
    
}
.profileIconDD + .MuiPopover-root
{
    .MuiMenu-paper
    {
        // background-color: #cee0e6;
        li
        {
            font-size: 13px;
        }
        
    }
}
.handleTitle
{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.handleProfileDD
{
    .MuiPopover-paper{
        top: 66px !important;
    }
}