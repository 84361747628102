.training_status
{
    position: relative;
    padding-left: 20px;
    top: -3px;
}
.training_status::before{
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    left: 6px;
    background-color: orange;
    top: 1px;
}
.training_status.completed::before{
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    left: 6px;
    background-color: green;
    top: 1px;
}
.spinIt
{
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 1s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }