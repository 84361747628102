@import './switch-toggle.scss';
*, html
{
    box-sizing: border-box;
}
body
{
    margin: 0;
    padding: 0;
    background-color: #fafafa;
}
button, input, textarea, select, select option {
  outline: none;
}
.text_white {color: #fff;}
.text_black{color: #000;}
.white_bg {background-color: #fff;}
.text-left{ text-align: left;}
.text-center{text-align: center;}
.text-right{text-align: right;}
.p0{ padding: 0;}
.pl0{padding-left: 0 !important }
.pr0{padding-right: 0 !important;}
.pt0{padding-top: 0;}
.pb0{padding-bottom: 0;}
.p20{padding: 20px;}
.p15{padding: 15px !important;}
.pr15{padding-right: 15px;}
.pl15{padding-left: 15px;}
.p12{padding: 12px;}

.m0{margin: 0;}
.ml0{margin-left: 0 !important;}
.mr0{margin-right: 0 !important;}
.mt0{margin-top: 0;}
.mt8{margin-top: 8px;}
.mt5{margin-top: 5px;}
.mt10{margin-top: 10px;}
.mb10{margin-bottom: 10px;}
.mb0{margin-bottom: 0;}
.m30{margin: 30px;}
.mb30{margin-bottom: 30px;}
.mb50{margin-bottom: 50px;}
.m20{margin: 20px;}
.mt20{margin-top: 20px;}
.m15{margin: 15px;}
.m12{margin: 12px;}
.ml5{margin-left: 5px;}
.mr5{margin-right: 5px;}
.ml20{margin-left: 20px;}
.mr20{margin-right: 20px;}
.font12{font-size: 12px;}
.font13{font-size: 13px;}
.font14{font-size: 14px;}
.font15{font-size: 15px;}
.font16{font-size: 16px;}
.font18{font-size: 18px;}

.fontWeight500{font-weight: 500;}
.fontWeight600{font-weight: 600;}
.fontWeight700{font-weight: 700;}
.fontWeight800{font-weight: 800;}

.nowrap{white-space: nowrap;}
.w100{width: 100% !important;}
.pos-rel{position: relative;}
.in-block{display: inline-block;}
.display-flex
{
  display: flex;
  justify-content: space-between;
}
.flex{display: flex;}
.base-line{align-items: baseline;}
.hideIt{display: none;}
.vertical-7 {
  vertical-align: -7px;
}
.textGreen
{
  color: #279600 !important;
} 
.verticalTop
{
  vertical-align: top;
}
.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.sxp-defaultDropDown {
  background-color: #086284;
  color: #fff !important;
  padding: 10px 20px;
  border-radius: 14px;
  .MuiSelect-icon {
    color: #fff;
  }
  &:before,
  &:after {
    border-bottom: 0 !important;
  }
  &:hover:not(.Mui-disabled):before {
    border-bottom: 0 !important;
  }
  .MuiInputBase-input {
    padding: 0px;
  }
}
.sxp-defaultDropDown_filed
{
  background-color: #B1D0DC;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 13px !important;
  .MuiSelect-icon {
    color: #086284;
  }
  &:before,
  &:after {
    border-bottom: 0 !important;
  }
  &:hover:not(.Mui-disabled):before {
    border-bottom: 0 !important;
  }
  .MuiInputBase-input {
    padding: 0px;
  }
}
.borderRadius6
{
  border-radius: 6px;
}
.layoutContent
{
  margin-left: 260px;
  margin-top: 88px;
  margin-right: 20px;
  transition: 0.3s;
}
.largeLayoutContent
{
  margin-left: 119px;
}
.sxp-btn {
  font-weight: 500;
  color: #fff;
  width: auto;
  border: 0;
  font-size: 14px;
  padding: 5px 20px;
  border-radius: 8px;
  transition: 0.3s;
  cursor: pointer;
  height: 34px;
}

.btn-primary {
background-color: #086284;
box-shadow: none;
}
.primaryLarge
{
  width: 200px !important;
}


// expansion card all styles globally
.cardFull
{
  padding: 0 30px;
}
.cardSpaceArrow
{
  padding-left: 42px;
}
.table_head
{
  margin-top: 30px;
  padding-bottom: 20px;
  p{margin: 0;}
}
.CustomexpansionPannel
{
  margin-bottom: 20px;
  border-radius: 8px !important;
  box-shadow: none !important;
  .iconExpand {
    color: #086284;
  }
  .MuiIconButton-root
  {
    order: -1;
  }
  .MuiExpansionPanelSummary-root
  {
    padding-left: 0;
    padding-right: 0;
  }
  .inner_expandBlock
  {
    display: block !important;
    background-color: #eeeeee;
    justify-content: space-around;
    align-items: baseline;
    padding: 16px !important;
  }
  .MuiExpansionPanelSummary-content.Mui-expanded
  {
    margin: 12px 0;
  }
  .boxShadow {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15) !important;
    border-radius: 8px !important;
  }
  &::before
  {
    background-color: transparent !important;
  }
}
.overflowTextdots
{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.filterMainBlock {
  background-color: #cee0e6;
  padding: 20px;
  width: 350px;
  position: fixed;
  right: -350px;
  z-index: 100;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  top: 67px;
  transition: 0.3s;
  max-height: calc(100vh - 66px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.activeFilter {
  right: 0;
}
.searchInput {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  input {
    width: 100%;
    border-radius: 50px;
    border: 0;
    height: 34px;
    padding-left: 40px;
  }
  .searchIcon {
    position: absolute;
    left: 15px;
    top: 9px;
  }
  .downArrrowChev {
    right: 8px;
    position: absolute;
    width: 33px;
    top: 0px;
  }
}
.clearAllText {
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}
.recentSearches {
  margin-bottom: 20px;
  h4 {
    margin-bottom: 7px;
    font-size: 14px;
  }
}
.block-btn {
  width: 100% !important;
}
.btn-danger {
  background-color: #ec5b56;
  box-shadow: none;
}
.label_pink {
  background-color: #fee3ec;
  color: #fa5087;
  font-size: 12px;
  padding: 4px 10px;
  border-radius: 4px;
}

.recommend-btn
{
    border-radius: 8px;
    padding: 0 18px;
    height: 34px;
    color: #fff;
    font-size: 12px;
    border: transparent;
    background: #086284;
}
.searchFilter
{
    .searchIcon
    {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
    input
    {
        height: 34px;
        background-color: #C6D8DF;
        border-radius: 8px;
        border: 0;
        padding-left: 35px;
        font-size: 13px !important;
        width: 224px;
        margin-right: 10px;
    }
    .downArrrowChev {
      right: 8px;
      position: absolute;
      width: 33px;
      top: 0px;
    }
}
.deleteIcon
{
  background-color: #ec5b56;
  color: red;
  position: absolute;
  right: 0;
  top: 0;
  padding: 7px;
  border-radius: 0px 8px 0 0; 
}

.newVar
{
    background-color: #fff;
    box-shadow: 0 4px 25px rgba(0,0,0,.15)!important;
    border-radius: 8px!important;
    padding: 20px 0;
}
.varHead {
  margin-bottom: 5px;
  margin-right: 15px;
  margin-top: 5px;
}

.select_Placeholder
{
  margin-top: -17px !important;
  background-color: #086284;
  color: #fff !important;
  padding: 2px 19px;
  border-radius: 8px;
}

input.errorInput, textarea.errorInput 
{
  background-color: rgba(236, 91, 86, 0.2) !important;
}
.errorFieldMsg {
  display: block;
  font-size: 12px;
  text-align: left;
  margin-left: 8px;
  color: #ec5b56;
}
.sxp-defaultDropDown_filed.errorInput
{
  background-color: rgba(236, 91, 86, 0.2) !important;
}
.errorInput .MuiSelect-icon
{
  color: rgb(236, 91, 86);
}
.resetBtn
{
  background-color: #086284;
  border-radius: 8px;
  color: #fff;
  border:0;
  padding: 7px 10px;
  margin-top: 10px;
}
.disablePagBtn {
  pointer-events: none;
  opacity: 0.45;
  cursor: not-allowed;
}