.urlBg
{
    background-color: #c6c6c6;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 5px;
}
.curlBg
{
    background-color: #c6c6c6;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 5px;
    position: relative;
    span
    {
        position: absolute;
        right: 0;
        top: 0;
        background-color: #086284;
        color: #fff;
        padding: 6px 14px;
        border-radius: 0 5px 0 0;
    }
}
.docs_data
{
    h4{}
    p{font-size: 14px;}
}
.schemaFields
{
    div{
        width: 220px;
        height: 34px;
        border-radius: 8px;
        background-color: rgba(8, 98, 132, 0.2);
        font-size: 13px;
        padding: 9px 14px;
        margin-bottom: 10px;
        margin-right: 10px;
        pointer-events: none;
        color: #555;
    }
}