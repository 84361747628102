.leftMenuComp
{
    margin-top: 88px;
    width: 213px;
    background-color: #086284;
    padding: 38px 0;
    margin-left: 22px;
    margin-right: 15px;
    overflow: hidden;
    border-radius: 26px;
    position: absolute;
    top: 0;
    transition: 0.3s;
}
.smallMenuBar
{
  width: 72px;
}
.listData li {
    cursor: pointer;
    list-style-type: none;
    border-radius: 26px 0 0 26px;
    position: relative;
  }
  .listData li a {
    padding: 15px 0px;
    display: block;
    text-decoration: none;
  }
  .listData li a.active {
    background-color: #fafafa;
    margin-left: 8px;
    border-radius: 12px 0 0 12px;
  }
  .listData li a img {
    position: absolute;
    left: 21px;
    top: 25px;
    transform: translateY(-50%);
    width: 26px;
  }
  .listData li a span {
    text-align: left;
    display: block;
    font-size: 13px;
    color: #fff;
    padding-left: 73px;
  }
  .listData li a.active span {
    color: #086284;
    margin-left: -7px;
  }
  .listData li a.active .imageActive {
    display: block;
  }
  .listData li a.active .ImgInactive {
    display: none;
  }
  .iconsDropNo {
    position: absolute;
    right: 6px;
    top: 6px;
    font-size: 24px !important;
  }
  .slideDropDown {
    overflow: visible !important;
  }
  .slideDropDownConfA {
    overflow: visible !important;
  }
  .slideDropDownConfB {
    overflow: visible !important;
  }
  .listData li ul
  {
    height: 0;
    transition: 0.3s;
    overflow: hidden;
    padding-left: 12px;
    background-color: #004e6b;
  }
 