.toggle-parent-modeller {
    width: 50px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    padding: 2px;
    height: 25px;
    background: #086284;
    border-radius: 8px;
    position: absolute;
    div
    {
        transition: all 0.3s ease;
    }
}
.inactiveToggle {
    background-color: #c4c4c4 !important;
    transition: all 0.3s ease;
}
.toggle-button {
    background-color: #ffff;
    left: 2px;
    width: 23px;
    height: 21px;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(28, 41, 90, 0.0367952);
    border-radius: 6px;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    cursor: pointer;
    padding: 8px 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    min-width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: unset;
    box-sizing: border-box;
  
    position: absolute;
    left: 24px;
  }

  .disabled-toggle {
    left: 2px;
    width: 23px;
    height: 21px;
    box-shadow: 0px 4px 6px rgba(28, 41, 90, 0.0367952);
    border-radius: 6px;
    background-color: #ffffff !important;
  }

