.bussinesMExpan {
    .description {
      font-size: 12px;
      color: #086284;
      margin-left: 88px;
      span {
        font-weight: 600;
      }
    }
    .flex {
      align-items: baseline;
      margin-bottom: 15px;
      position: relative;
    }
    input,
    select {
      width: 240px;
      margin: 0 7px;
    }
    textarea {
      width: 240px;
      margin: 0 7px;
      height: 134px;
      border: 0;
      background-color: rgba(8, 98, 132, 0.2);
      border-radius: 8px;
      padding-left: 14px;
      padding-top: 7px;
    }
    .formField {
      padding-left: 160px;
    }
    .customInputStyle {
      .MuiSwitch-root {
        margin: 0;
      }
      label {
        font-weight: 600;
        margin-left: 40px;
        margin-right: 10px;
      }
    }
}
.label_orange {
    background-color: #f58619;
    align-items: baseline;
    color: #fff;
    font-size: 14px;
    width: 62px;
    padding: 3px 7px;
    border-radius: 4px;
    position: absolute;
    left: -72px;
    top: 5px;
}

.formField input, .formField .sxp-defaultDropDown_filed {
    height: 34px;
    border: 0;
    width: 194px;
    background-color: rgba(8, 98, 132, 0.2);
    border-radius: 8px;
    padding-left: 14px;
    margin: 0 5px;
}