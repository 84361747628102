.desc
{
    font-size: 12px;
    color: #086284;
}
.handleHeadReco
{
    h4{
        font-size: 16px;
        font-weight: 600;
    }
    .MuiSelect-select.MuiSelect-select
    {
        padding-top: 0 !important;
    }
    input[type="file"]
    {
        display: none;
    }
    .sxp-defaultDropDown_filed
    {
        width: 180px;
        margin-right: 8px;
        margin-bottom: 8px;
        margin-top: 0;
        background-color: #B1D0DC !important;
    }
   
}
.handleSecondUpload
{
    margin-bottom: 8px;
    background-color: #B1D0DC;
    border-radius: 8px;
    .sxp-defaultDropDown_filed
    {
        margin-bottom: 0;
        width: 140px;
    }
    .icons-upload
    {
        width: 14px;
        margin-top: 10px;
        margin-right: 10px;
    }
}

.handleArrowIncDec{
    background-color: #B1D0DC;
    align-self: end;
    border-radius: 8px;
    margin-right: 20px;
}
.removeIcon
{
    align-self: center;
    margin-top: -7px;
    margin-left: 7px;
}
.text-val
{
    font-size: 13px;
    color: #086284;
    padding-top: 6px;
}
.handleVisiIcon
{
    .icons-hide
    {
        margin-left: 10px;
        background-color: #B1D0DC;
        height: 24px;
        padding: 6px;
        border-radius: 4px;
    }
}
.handleFields
{
    .part{
        flex: 1;
        margin: 10px;
        input, textarea{
            width: 100%;
            background-color: #B1D0DC;
            height: 34px;
            border: 0;
            margin-top: 10px;
            border-radius: 8px;
            padding-left: 10px;
        }
        textarea
        {
            height: 70px;
            border-radius: 8px;
            padding-top: 10px;
        }
    }
}