.twotabsUI {
    ul {
      margin-left: 20px;
        li {
            list-style-type: none;
            display: inline-block;
            font-size: 13px;
            font-weight: 600;
            background-color: #eeeeee;
            padding: 8px 12px;
            color: rgba(8, 98, 132, 0.4);
            cursor: pointer;
            &:first-child {
            border-radius: 8px 0px 0px 8px;
            }
            &:last-child {
            border-radius: 0px 8px 8px 0px;
            }
        }
    }
}
.activeApi {
    background-color: #007caa !important;
    color: #fff !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
.active3Api {
    background-color: #f58819 !important;
    color: #fff !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
.activeNLUApi {
    background-color: #14b3d7 !important;
    color: #fff !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
.customInputStyle
{
    margin-right: 38px;
    margin-bottom: 20px;
    label
    {
        font-weight: 600;
        margin-right: 10px;
        font-size: 12px;
        display: inline-block;
    }
}
.customInputStyle
{
    .toggle-parent-modeller
    {
        position: relative;
        display: inline-block;       
    }
}

.customInputStyle
{
    label {
    font-size: 12px;
    margin-top: 12px;
    display: inline-block;
    margin-left: 5px;
    }       
}
    .footer {
    background-color: #dadada;
    padding: 15px;
    }
    .JsonHeader {
        margin-left: 20px;
        label
        {
            font-size: 12px;
            margin-top: 20px;
            display: inline-block;   
        }
        textarea
        {
            background-color: rgba(8, 98, 132, 0.2) !important;
            border: 0;
            padding: 7px !important;
            font-size: 13px;
            border-radius: 8px !important;
        }
    }
    .formField {
    padding-left: 40px;
    }
.apiServiceSelectPlaceHolder
{
    position: absolute;
    left: 15px;
    top: 5px;
}

.h-w-auto{
    height: auto !important;
    width: auto !important;
}
.inputMB10
{
    input{
        margin-bottom: 10px !important;
    }
}
.OauthSelectedData
{
    margin-left: 60px;
    margin-top: 30px;
    h4{
        margin-bottom: 10px;
    }
    p{
        font-size: 12px;
        color: #007caa;
        font-weight: 600;
        span{
            color: #333;
            display: inline-block;
            margin-left: 5px;
        }
    }
}