
.generetBtn {
    background-color: #cee0e6;
    height: 34px;
    color: #086284;
    img{
        margin-right: 5px;
        vertical-align: -5px;
    }
}
.filterBtn {
    height: 34px;
}
.statusMsg
{
    font-weight: 700;
    color: #1932D2
}
.statusMsg.Successful {
    color: #009711 !important;
  }
  .statusMsg.Abandoned {
    color: #cf6000 !important;
  }
  .statusMsg.Inprogress {
    color: #1932d2 !important;
  }
  .statusMsg.Cancelled {
    color: #d2110b !important;
}
.chat_block 
{
    margin: 0 160px;
    background-color: #ffffff;
}
.chatHeader
{
    h5
    {   
        font-size: 16px;
        color: #000;
        margin-left: 20px;
        margin-top: 14px;
        margin-bottom: 12px;
    }
}
.chatbody
{
    height: 360px;
    padding: 20px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 10px;
      }
      
      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1; 
      }
       
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #b5d0da; 
        border-radius:20px
      }
      
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #086284; 
        border-radius:20px
      }
    .receiver
    {
        margin-bottom: 18px;
        .message
        {
            background-color: rgba(9, 98, 131, 0.3);
            color: #121212;
            box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
            border-radius: 10px 10px 0px 10px;
            font-size: 13px;
            padding: 11px 42px;
            margin-left: 10px;
            position: relative;
            max-width: 60%;
            .dateTime
            {
                position: absolute;
                width: auto;
                left: 0px;
                bottom: -22px;
                transform: translateY(-50%);
                color: #505050;
                font-size: 10px;
            }
        }
    }
    .sender
    {
        margin-bottom: 18px;
        .flex
        {
            justify-content: flex-end;
            .message
            {
                background-color: rgb(255, 255, 255);
                color: #121212;
                box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
                border-radius: 10px 10px 0px 10px;
                font-size: 13px;
                padding: 11px 42px;
                margin-right: 10px;
                position: relative;
                max-width: 60%;
                .dateTime
                {
                    position: absolute;
                    width: auto;
                    right: 0px;
                    bottom: -22px;
                    transform: translateY(-50%);
                    color: #505050;
                    font-size: 10px;
                }
            }
        }
    }
}
.userIcon
{
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    background-color: #000;
    text-align: center;
    img
    {    padding: 3px;
        width: 100%;}
}
.clientIcon
{
    width: 20px;
    height: 20px;
    display: inline-block;
    
    text-align: center;
    img
    {    padding: 3px;
        width: 100%;}
}
.dateBlockPicker {
    .react-daterange-picker {
      width: 100%;
    }
    .react-daterange-picker__calendar {
      width: 300px;
    }
    .sxp-defaultDatePicker {
      background-color: #b1d0dc;
    }
    .sxp-defaultDatePicker input {
      color: #086284;
    }
    .sxp-defaultDatePicker .react-date-picker__inputGroup__divider,
    .sxp-defaultDatePicker .react-daterange-picker__range-divider {
      color: #086284;
    }
    .sxp-defaultDatePicker svg {
      stroke: #086284;
    }
    input::placeholder,
    .react-date-picker__inputGroup__divider,
    .react-daterange-picker__range-divider {
      color: #086284;
      font-size: 13px;
    }
    .react-daterange-picker__wrapper
    {
      border:0
    }
  }
  .sxp-defaultDatePicker {
    background-color: #b1d0dc;
    padding: 3px 6px;
    font-size: 16px;
    border-radius: 8px;
    .react-daterange-picker__wrapper {
      border: 0;
    }
    input {
      color: #086284;
    }
    .react-daterange-picker__calendar-button:hover svg,
    .react-daterange-picker__clear-button:hover svg {
      stroke: #086284 !important;
    }
    svg,
    svg:hover {
      color: #086284;
      stroke: #086284;
      width: 16px;
    }
  
    input::placeholder,
    .react-date-picker__inputGroup__divider,
    .react-daterange-picker__range-divider {
      color: #086284;
    }
  
    .react-calendar__tile--now,
    .react-calendar__tile--now:hover {
      background-color: #b7d5e0 !important;
      border-radius: 8px;
    }
    .react-calendar__tile:enabled:hover {
      background-color: #b7d5e0;
    }
    .react-calendar__tile--active {
      background-color: #375b69 !important;
      border-radius: 0px;
    }
}
.labelFilter {
    color: #086284;
    background-color: #b1d0dc;
    font-size: 12px;
    padding: 7px 20px;
    display: inline-block;
    border-radius: 50px;
    margin-right: 7px;
    cursor: pointer;
    margin-bottom: 7px;
}
.activeSelectedFilter {
    background-color: #086284;
    color: #fff;
}
.sortIconbg {
    background-color: #086284;
    margin-left: 4px;
    padding: 2px 3px;
    border-radius: 4px;
    display: inline-block;
    cursor: pointer;
    vertical-align: -1px;
}
.MuiAutocomplete-listbox	
{
    background-color: #cee0e6;
    font-size: 13px;
    color: #000;
    font-weight: 600;
}	
.MuiAutocomplete-option:hover
{
    background-color: #086284 !important;
    color: #fff;
}

.predefinedFilter {
  font-size: 12px;
  span:first-child {
    margin-right: 10px;
    font-weight: 600;
    color: #086284;
  }
  span {
    img {
      width: 8px;
      margin-left: 4px;
      cursor: pointer;
    }
  }
}
.FilterBadge {
  font-size: 12px;
  color: #086284;
  background-color: #b1d0dc;
  padding: 7px 11px;
  display: inline-block;
  margin-right: 7px;
  font-weight: 600;
  border-radius: 6px;
}
.table_grid
{
  p
  {
    margin-left: 12px;
  }
}
.billSummHead
{
  width: 14.4% !important;
}
.billSummBody
{
  width: 14.4% !important;
}