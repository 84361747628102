.pagination
{
    margin-top: 20px;
    font-size: 13px;
    button
    {
        border: 0;
        background-color: transparent;
        padding: 10px;
        vertical-align: middle;
        cursor: pointer;
    }
}
.rowsperpage
{
    border: 0;
    margin-right: 50px;
}
.disablePagBtn
{
    pointer-events: none;
    opacity: .45;
}