.description{
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 163.19%;
    color: #086284;
    margin-top: 5px;
    white-space: "pre"
}
.heading{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    margin-top: 6px;
    color: #000000;
}
.single_upload
{
    background-color: rgba(8, 98, 132, 0.2);
    border-radius: 8px;
    padding: 10px 16px;
    margin-bottom: 8px;
    margin-top: 8px;
    width: 300px;
    position: relative;
    p, .title_head1
    {
        font-size: 14px;
        color: #086284;
        font-weight: 600;
        margin: 0;
        opacity: 1;
        span{
            opacity: 0.5;
            font-weight: 400;
            font-size: 12px;
        }
    }
    .dwnldTemplate
    {
        vertical-align: -2px;
        margin-left: 14px;
        cursor: pointer;
        opacity: 1;
    }
}
.fileNameSide
{
    position: absolute;
    font-size: 13px;
    left: 103%;
    color: #086284;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 133px;
}
.vectorise_div_dd
{
    margin-top: 10px;
    background-color: rgba(8, 98, 132, 0.2);
    border-radius: 8px;
    padding: 0px 16px;
    .mergeTxt
    {
        font-size: 14px;
        color: #086284;
        font-weight: 600;
    }
    .param-ip
    {
        background: rgb(200, 218, 224);
        width: 280px;
        margin-left: 0;
        margin-top: 0;
        padding-left: 0;
    }
}
.reco-drpdwn{
    .MuiSelect-select.MuiSelect-select {
      padding-right: 24px;
      font-size: 13px;
      color: #086284;
      margin-left: 9px;
      padding-top: 6px;
      background: transparent;
  }
}
.refreshBtn
{
    background-color: rgb(8, 98, 132);
    padding: 3px 10px;
    color: #fff;
    font-size: 13px;
    border-radius: 4px;
    cursor: pointer;
}
.eyeIconBtn
{
    background-color: #086284;
    padding: 1px 7px;
    border-radius: 4px;
    margin: 0 6px;
    img
    {
        vertical-align: 1px;
    }
}
.param-ip{
    background: rgba(8, 98, 132, 0.2);
    border-radius: 8px;
    margin-top: 10px;
    border: transparent;
    width: 150px;
    height: 34px;
    padding-left: 20px;    
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #93BAC9;
    margin-left: 10px;
}
.fullWidthDD
{
    .param-ip
    {
        width: 100%;
    }
}
.perplexity_div
{
    margin-top: 29px;
    h4
    {
        margin: 0;
        color: #086284;
        font-size: 15px;
    }
    .block_per
    {
        background-color: rgba(8, 98, 132, 0.2);
        border-radius: 8px;
        color: #086284;
        padding: 9.5px 12px;
        padding-right: 60px;
        font-size: 13px;
        position: relative;
        margin-left: 10px;
        img
        {
            position: absolute;
            right: 10px;
            cursor: pointer;
        }
        .chevUp{
            top: 7px;
        }
        .chevDwn{
            transform: rotate(180deg);
            top: 20px;
        }
    }
}
.flexSpaceEqual
{
    display: flex;
    .singleBlock
    {
        flex:1;
        margin:1em
    }
}
.label_status{
    background-color: rgba(249, 136, 31, 0.2);
    border-radius: 4px;
    font-size: 13px;
    color: rgb(249, 136, 31);
    font-weight: 600;
    padding: 3px 10px;
    margin: 0 6px;
}
.green_succ
{
    background-color: rgba(45, 247, 67, 0.2);
    color: rgb(45, 247, 67);
}
.red_fail
{
    background-color: rgba(236, 91, 86, 0.2);
    color: rgb(236, 91, 86);
}
.visualPopup
{
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;        
    background-color: #fff;
    min-width: 30%;
    min-height: 30%;
    .load
    {
        margin-top: 50px;
    }
    iframe
    {
        width: 678px;
        height: 504px;
        border: 0;
    }
    .label_red
    {
        background-color: #FEE3EC;
        border-radius: 4px;
        font-size: 13px;
        padding: 4px 6px;
        color: #FA5087;
        font-weight: 600;
        margin-left: 30px;
        margin-top: 20px;
    }
    .closeBtn_v
    {
        margin-right: 30px;
        margin-top: 20px;
        cursor: pointer;
    }
}
.intentClassTableBody
{
    width: 25% !important;
}