.def_input
{
    border: 0;
    background-color: #B1D0DC;
    border-radius: 8px;
    padding-left: 14px;
    margin: 0 5px;
    height: 34px;
}
.checBoxAlign
{
    margin-top: 20px;
    display: inline-block;
    align-items: baseline;
}
.profileIconDD
{
    .MuiPopover-paper
    {
        left: auto !important;
        right: 16px;
        // background-color: #cee0e6;
        color: rgba(0, 0, 0, 0.54);
        .MuiMenuItem-root
        {
            font-size: 13px;
        }        
        .MuiSvgIcon-root
        {
            margin-right: 8px;
            height: 22px;
        }
    }
}
.projSelectPlaceholder
{
    position: absolute;
    top: 4px;
    left: 13px;
    color: #000 !important;
    font-size: 13px !important;
}
.inner_expandBlock
{
    input[type=checkbox]
    {   
        height: 34px;
    }
}
.rolesSpace50
{
    .table_grid
    {
        width: 50%;
    }
}