.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 3000;
}

.wrapper {
    display: inline-block;
}

.preview-btn {
    margin-left: 10px;
    margin-bottom: 4px;
    padding: 2px 4px;
    background-color: #c1c1c1;
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
}

.editor-content {
    background-color: #FBFBFB;
    padding: 8px 6px;
    border-radius: 6px;
    min-width: 800px;
    min-height: 600px;
}

.create-content {
    background-color: #FBFBFB;
    padding: 8px 6px;
    border-radius: 6px;
}