.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,.5);
}
.customModalStyles .Modal {
    height: calc(100vh - 120px);
    top: 80px;
}
.Modal{
    position: fixed;
    height: calc(100vh - 120px);
    z-index: 101;
    left: 50% !important;
    transform: translateX(-50%) !important;
    background-color: #fff;
    box-shadow: 0 19px 38px rgba(0,0,0,0.74);
    padding: 16px;
    overflow-y: scroll;
    width: 80%;
}
.tab-style input, .child-input
{
    height: 34px;
    border: 0;
    background-color: rgba(8,98,132,.2);
    border-radius: 8px;
    padding-left: 14px;
    margin: 0 10px;
    width: 92%;
}
.table-responsive{
    width: 100%;
    border-collapse: collapse;
}

.table-responsive tr td{
    position: relative;
    min-height: 35px;
    padding-bottom: 8px;
}

.table-responsive th { 
    // background: #030303; 
    color: white; 
    font-weight: bold; 
}
.table-responsive td, th { 
    line-height: 34px;
    vertical-align: middle; 
    // border: 1px solid #000; 
    text-align: center; 
}
.appDetialsBlock1
{
    position: absolute;
    position: absolute;
    width: 240px;
    right: 0px;
    background-color: #CEE0E6;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}
.appDetialsBlock
{    
    padding: 15px;
    width: 242px;
    margin: 0 auto;
    h4
    {
        font-size: 18px;
        margin: 0;
        color: #086284;
        margin-bottom: 10px;
    }
    input{
        height: 34px;
        max-width: 100% !important;
        width: 100% !important;
        border: 1px solid #086284 !important;
        background-color: transparent;
        border-radius: 8px;
        margin-bottom: 8px;
        padding-left: 10px;
    }
    textarea
    {
        width: 100%;
        height: 120px;
        resize: none;
        border: 1px solid #086284;
        background-color: transparent;
        border-radius: 8px;
        margin-bottom: 8px;
        padding-left: 10px;
        padding-top: 6px;
    }
    button
    {
        height: 34px;
        background-color: #086284;
        color: #fff;
        width: 100%;
        border: 0;
        border-radius: 8px;
    }
}
.modalCard
{
    padding: 12px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    background-color: #CEE0E6;
    position: absolute;
    top: 38px;
    width: 280px;
    z-index: 9;
    right: 0;
    h4
    {
        margin-top: 7px;
        margin-bottom: 7px;
        color: #086284;
        font-size: 18px;
        font-weight: 500;
    }
    textarea
    {
        width: 100%;
        background-color: transparent;
        resize: none;
        border: 1px solid #086284;
        border-radius: 8px;
        padding: 7px;
        height: 100px;
    }
    button
    {
        margin-top: 10px;
        width: 100% !important;
    }
}
.tableEqualSpaec
{
    h5{width: 20%;}
    p
    {
        width: 20%;
        font-size: 13px;
    }
}

.apiIdDiv
{
    position: absolute;
    width: 232px;
    border-radius: 50px;
    background-color: #CEE0E6;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 18px;
    left: 50%;
    transform: translate(-50%,0);
    p
    {
        color: #086284 !important;
        width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    img{

    }
}

.verMetaDa
{
    width: 600px;
    margin: 0 auto;
    .labelsGrid
    {
        span
        {
            width: 47%;
            margin-left: 15px;
            padding-left: 40px;
            display: inline-block;
            font-size: 14px; 
            &:last-child{
                padding-left: 7px;
            }
        }        
    }  
    .formField{
        input{
            width: 45%;
        }
    } 
}