.FirstProj
{
    position: fixed;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    padding: 20px;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.7);
    .mainFPdiv
    {
        background-color: #fff;
        padding: 60px;
        position: absolute;
        left: 50%;
        top: 20%;
        transform: translateX(-50%);
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.31);
    }
    .FieldpGroup
    {
        margin-top: 30px;
    }
}