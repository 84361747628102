.select {
  border: 1px solid #086284;
  border-radius: 8px;
  width: 100%;
  height: 33px;
  font-size: 16px;
  color: #086284;
  padding-left: 20px;
  outline: none;
  background-color: transparent;
  margin-bottom: 15px;
}
.pretask_placeholder {
  position: absolute;
  top: 5px;
  left: 12px;
  z-index: 0;
}
.text-area {
  min-width: 100%;
  max-width: 100%;
  padding-top: 10px;
}
