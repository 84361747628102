.includedBadge {
    background-color: #e3fef9;
    border: 1px solid #979797;
    font-size: 13px;
    font-weight: 600;
    border-radius: 27px;
    padding: 7px 20px;
    height: 34px;
    display: inline-block;
    margin-left: 10px;
}
.forCalend
{
    overflow-x: visible;
    overflow-y: visible;
}
.sliderStyles {
  color: #086284 !important;
  .MuiSlider-rail {
    height: 6px;
  }
  .MuiSlider-mark {
    display: none;
  }
  .MuiSlider-track {
    height: 6px;
  }
  .MuiSlider-thumb {
    margin-top: -3px;
  }
}
.filedsHeader {
  div {
    display: inline-block;
    text-align: left;
    margin: 0 5px;
    width: 182px;
    font-size: 13px;
    font-weight: 600;
    &:first-child {
      margin-left: -12px;
    }
  }
}
.formField {
  margin-top: 7px;
  margin-bottom: 7px;
  input,
  select {
    height: 34px;
    border: 0;
    background-color: rgba(8, 98, 132, 0.2);
    border-radius: 8px;
    padding-left: 14px;
    margin: 0 5px;
    option {
      color: #000 !important;
      font-weight: 500 !important;
      padding: 10px;
      background-color: #cee0e6;
    }
  }
  button {
    svg {
      width: 18px;
    }
  }
}

.trashTransparent
{
  background-color: transparent;
  border: 0;
  padding: 8px;
  cursor: pointer;
}
.text_black, .table_head
{
  .table_grid
  {
    width: 16.6%;
  }
}
.billSumHead, .billSumBody
{
  width: 20% !important;
}

@media only screen and (max-width : 1340px) {
  .billSummHead
  {
    p{font-size: 12px;margin-left: 0;}
  }
}